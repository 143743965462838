import { Grade } from '@generated/graphql';

const formatNumber = (
  titles: string[],
  num: number | null | undefined,
  onlyTitle?: boolean,
) => {
  const currentNumber = num ?? 0;
  // titles example ['день', 'дня', 'дней']
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    (onlyTitle ? '' : `${Intl.NumberFormat().format(currentNumber)} `)
    + titles[
      Math.abs(<number>currentNumber) % 100 > 4 && Math.abs(<number>currentNumber) % 100 < 20
        ? 2
        : cases[Math.abs(<number>currentNumber) % 10 < 5 ? Math.abs(<number>currentNumber) % 10 : 5]
    ]
  );
};

const generateOptions = (object: object) => Object.entries(object)
  .reduce((acc: object[], current: string[]) => {
    const newValue = {
      displayName: current[1],
      id: current[0],
    };
    acc.push(newValue);
    return acc;
  }, []);

const gradeColor = (grade: Grade) => {
  if (grade === Grade.Junior) return 'green';
  if (grade === Grade.Middle) return 'orange';
  return 'red';
};

const createUserName = (
  user:
  | {
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    middleName?: string | null | undefined;
  }
  | undefined
  | null,
) => {
  if (!user) return '—';
  const { firstName, lastName, middleName } = user;
  return firstName || lastName || middleName ? [firstName, lastName, middleName].join(' ') : '—';
};

const getInitials = (name:string = '', join: string = '') => {
  const nameArr = name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase());
  return nameArr.length === 0 || nameArr.length === 1 ? nameArr.join('') : nameArr.join(join);
};

export {
  generateOptions, formatNumber, gradeColor, createUserName, getInitials,
};

import {
  Alert,
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import Form from 'components/form/Form';
import { useFieldArray, useForm } from 'react-hook-form';
import TextFieldControl from 'components/form/TextFieldControl';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import {
  Grade, Question_Type, TopicQuery, useCreateOrUpdateQuestionMutation, useQuestionSubtypesQuery,
} from '@generated/graphql';
import AutocompleteControl from 'components/form/AutocompleteControl';
import TriangleArrow from 'assets/icons/TriangleArrow';
import Box from '@mui/material/Box';
import { Add, Close } from '@mui/icons-material';
import { formatNumber } from 'helpers/index';
import { GRADE_OPTIONS } from 'constants/global';
import { useParams } from 'react-router-dom';
import SelectControl from 'components/form/SelectControl';
import { QuestionItemType } from 'pages/Topic/view/types/topic';

type UpdateQuestionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isCreate?: boolean;
  topic: TopicQuery['topic'];
  question?: QuestionItemType;
  refetchTopic: any;
};

type FormInputs = {
  time: string | number;
  title: string;
  competence: {
    title: string;
  };
  grade: { id: Grade, displayName: string };
  criteria: any[];
  recommendedTime?: string
  questionText: string;
  type: Question_Type | undefined;
  questionSubtype: any;
};

export const QUESTION_TYPES = [
  {
    label: 'Практический',
    value: Question_Type.Practical,
  },
  {
    label: 'Теоретический',
    value: Question_Type.Theoretical,
  },
];

const UpdateQuestionModal = ({
  isOpen, onClose, isCreate, topic, question, refetchTopic,
}: UpdateQuestionModalProps) => {
  const { topicId } = useParams();
  const { addToast } = useContext(ToastContext);
  const title = isCreate ? 'Создание вопроса' : 'Редактирование вопроса';
  const isConfirm = question?.confirmation;

  const {
    data: questionSubtypesData,
    loading: questionSubtypesLoading,
  } = useQuestionSubtypesQuery();

  const questionSubtypes = questionSubtypesData?.questionSubtypes
    .map((subtype) => ({
      id: subtype,
      displayName: subtype,
    }));

  const [updateQuestion] = useCreateOrUpdateQuestionMutation();

  const form = useForm<FormInputs>({
    values: {
      competence: {
        title: topic?.competence.title!,
      },
      title: topic?.title!,
      time: question ? question.time / 60 : 1,
      criteria: question?.criterion!,
      recommendedTime: question?.recommendedTime ? formatNumber(['минута', 'минуты', 'минут'], Math.ceil((question.recommendedTime) / 60)) : undefined,
      questionText: question?.text!,
      grade: GRADE_OPTIONS.find((grade) => grade.id === question?.grade)!,
      type: QUESTION_TYPES?.find((type) => type.value === question?.type)?.value,
      questionSubtype: questionSubtypes?.find((type) => type.id === question?.subtype),
    },
  });

  const {
    handleSubmit, setValue, getValues, control, watch,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'formId',
    name: 'criteria',
  });

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const onSubmit = (formData: FormInputs) => {
    const time = typeof formData.time === 'string' ? parseInt(formData.time, 10) : formData.time;
    updateQuestion({
      variables: {
        input: {
          criterion: formData.criteria,
          grade: formData.grade.id,
          id: question?.id,
          text: formData.questionText,
          time: time * 60,
          topicId: topicId!,
          type: formData.type,
          subtype: formData.questionSubtype.id,
        },
      },
    }).then(() => {
      addToast({ type: ToastTypeEnum.SUCCESS });
      refetchTopic();
      onClose();
    }).catch(onError);
  };

  const handleChangeMinutes = (action: 'up' | 'down') => {
    const currentValue = getValues('time') as number;
    let newValue = currentValue;
    if (action === 'up') {
      newValue = currentValue + 1;
    } else if (currentValue > 1) {
      newValue = currentValue - 1;
    }
    setValue('time', newValue);
  };

  const questionTypeWatch = watch('type');

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle variant="h24" textAlign="center">
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        {(!isCreate && isConfirm) && (
          <Alert sx={{ mb: 3, px: 1.5, py: 1 }}>
            При&nbsp;изменении подтвержденного вопроса&nbsp;— вопрос будет
            перенаправлен в&nbsp;список неподтвержденных вопросов.
            Для&nbsp;использования вопроса в&nbsp;рамках тестирований
            необходимо подтвердить данный вопрос.
          </Alert>
        )}
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl name="competence.title" disabled label="Компетенция" />
            <TextFieldControl name="title" disabled label="Топик" />
            <AutocompleteControl
              options={GRADE_OPTIONS}
              name="grade"
              label="Грейд"
              rules={{
                required: true,
              }}
            />
            <SelectControl
              name="type"
              items={QUESTION_TYPES}
              sx={{ backgroundColor: 'bgSwitch' }}
              label="Тип вопроса"
              rules={{
                required: true,
              }}
            />
            {
              questionTypeWatch === Question_Type.Practical && (
                <AutocompleteControl
                  name="questionSubtype"
                  options={questionSubtypes || []}
                  loading={questionSubtypesLoading}
                  label="Подтип"
                  rules={{
                    // eslint-disable-next-line max-len
                    validate: (value, formValues) => !(formValues.questionType === Question_Type.Practical && !value),
                  }}
                />
              )
            }
            <TextFieldControl name="questionText" label="Текст вопроса" multiline rows={4} rules={{ required: true }} />
            <TextFieldControl
              rules={{
                validate: (value: number) => (!!value && !Number.isNaN(value) ? value > 0 : false),
              }}
              name="time"
              label="Время ответа"
              handleChange={(value: string, onChange: any) => onChange(value.replace(/\D/g, '').trim())}
              endIcon={(
                <Stack>
                  <TriangleArrow
                    onClick={() => handleChangeMinutes('up')}
                    sx={{
                      color: 'base.200', width: 14, height: 14, cursor: 'pointer',
                    }}
                  />
                  <TriangleArrow
                    onClick={() => handleChangeMinutes('down')}
                    sx={{
                      color: 'base.200', transform: 'rotate(180deg)', width: 14, height: 14, cursor: 'pointer',
                    }}
                  />
                </Stack>
              )}
            />
            {!isCreate && <TextFieldControl name="recommendedTime" label="Рекомендуемое время ответа" disabled />}
            <Box sx={{ backgroundColor: 'bgSwitch', p: 2 }}>
              <Stack spacing={2}>
                <Typography variant="body14rg" color="text.secondary">Критерии ответа</Typography>
                {
                  fields.map((field, key) => (
                    <TextFieldControl
                      multiline
                      rows={2}
                      endIcon={fields?.length > 1 && (
                        <IconButton onClick={() => remove(key)}>
                          <Close sx={{ color: 'base.200' }} />
                        </IconButton>
                      )}
                      key={field.formId}
                      name={`criteria.${key}`}
                      rules={{ required: true }}
                      placeholder="Текст критерия"
                      sx={{
                        '& > .MuiInputBase-root': {
                          backgroundColor: 'white',
                        },
                        '& .MuiInputAdornment-root ': {
                          alignItems: 'flex-end',
                          height: 24,
                        },
                      }}
                    />
                  ))
                }
                <Button
                  startIcon={<Add />}
                  sx={({ typography }) => ({
                    px: 0, justifyContent: 'flex-start', color: 'base.200', ...typography.body14rg,
                  })}
                  onClick={() => append(undefined)}
                >
                  критерий
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <LoadingButton variant="contained" onClick={handleSubmit(onSubmit, onError)}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateQuestionModal;

UpdateQuestionModal.defaultProps = {
  isCreate: false,
  question: undefined,
};

import React, {
  createContext, ReactNode, useMemo, useState,
} from 'react';
import ConfirmModal from 'components/modals/ConfirmModal';

type ModalProps = {
  title: string;
  loading?: boolean;
  handleClick: () => void | Promise<void>;
  actionButtonText?: string;
  description?: string;
};

interface ModalContextType {
  handleOpen: (modalProps: ModalProps) => void;
}

export const ConfirmModalContext = createContext<ModalContextType>({
  handleOpen: () => {},
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<ModalProps>({
    title: '',
    loading: false,
    handleClick: async () => {},
  });

  const handleOpen = ({ title, handleClick, ...rest }: ModalProps) => {
    setContent({ title, handleClick, ...rest });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { handleClick } = content;

  const onSubmit = async () => {
    try {
      setContent({ ...content, loading: true });
      await handleClick();
      setContent({ ...content, loading: false });
      handleClose();
    } catch (e) {
      setContent({ ...content, loading: false });
    }
  };

  const providerValue = useMemo(() => ({ handleOpen }), [handleOpen]);

  return (
    <ConfirmModalContext.Provider value={providerValue}>
      <ConfirmModal open={open} onClose={handleClose} {...{ ...content }} handleClick={onSubmit} />
      {children}
    </ConfirmModalContext.Provider>
  );
};

import { Add } from '@mui/icons-material';
import Button from '@mui/material/Button';
import React, { MouseEvent } from 'react';
import { SxProps, Theme } from '@mui/material';

interface AddButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  buttonText: string;
  sx?: SxProps<Theme>;
}

const AddButton: React.FC<AddButtonProps> = ({ onClick, buttonText, sx }) => {
  const defaultStyles: SxProps<Theme> = (theme) => ({
    px: 0,
    justifyContent: 'flex-start',
    color: 'base.200',
    ...theme.typography.body14rg,
  });

  const combinedStyles: SxProps<Theme> = (theme) => ({
    ...(typeof defaultStyles === 'function' ? defaultStyles(theme) : defaultStyles),
    ...(typeof sx === 'function' ? sx(theme) : sx),
  });

  return (
    <Button
      startIcon={<Add />}
      sx={combinedStyles}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

AddButton.defaultProps = {
  sx: undefined,
};

export default AddButton;

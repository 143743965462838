import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';
import { formatForFilter } from 'helpers/date';
import { GRADE_OPTIONS } from 'constants/global';
import { Grade } from '@generated/graphql';

const filterFields = [
  {
    name: 'createdAt',
    label: 'Дата создания',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => formatForFilter(value, 'start'),
  },
  {
    name: 'title',
    label: 'Название компетенции',
    inputType: InputTypes.TEXT,
  },

  {
    name: 'grades',
    label: 'Грейд',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: GRADE_OPTIONS,
    customFilter: (grades: { id: string; displayName: Grade }[]) => grades.map((grade) => grade.id),
    limitTags: 1,
    multiple: true,
  },

];

export default filterFields;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DownloadIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M18 15.5V18.5H6V15.5H4V18.5C4 19.6 4.9 20.5 6 20.5H18C19.1 20.5 20 19.6 20 18.5V15.5H18ZM17 11.5L15.59 10.09L13 12.67V4.5H11V12.67L8.41 10.09L7 11.5L12 16.5L17 11.5Z"
        fill="#1749D1"
      />
    </svg>

  </SvgIcon>
);

export default DownloadIcon;

import React, { FC } from 'react';
import {
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions, FilterOptionsState, Stack, TextField, Typography,
} from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FieldError } from 'react-hook-form';
import { get, set } from 'lodash';

export interface AutocompleteProps {
  options?: any[] | undefined | null;
  optionName?: string;
  equalityParam?: string;
  multiple?: MuiAutocompleteProps<any, any, any, any>['multiple'];
  disabled?: MuiAutocompleteProps<any, any, any, any>['disabled'];
  onInputChange?: MuiAutocompleteProps<any, any, any, any>['onInputChange'];
  placeholder?: string;
  loading?: MuiAutocompleteProps<any, any, any, any>['loading'];
  getOptionLabel?: MuiAutocompleteProps<any, any, any, any>['getOptionLabel'];
  freeSolo?: MuiAutocompleteProps<any, any, any, any>['freeSolo'];
  label?: string;
  value: any;
  onChange: (value: any) => void;
  error?: FieldError;
  limitTags?: number;
  defaultValue?: any;
  onChangeTextField?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
  React.ChangeEvent<HTMLInputElement>;
  filterOptionsParams?: (newValue: FilterOptionsState<any>) => FilterOptionsState<any>,
  formatDataBeforeOnChange?: (data: any) => any,
}
const Autocomplete: FC<AutocompleteProps> = ({
  options,
  optionName = 'displayName',
  multiple,
  disabled,
  onInputChange,
  placeholder,
  equalityParam = 'id',
  loading,
  getOptionLabel = (option) => get(option, optionName) || '',
  freeSolo,
  label,
  value,
  error,
  onChange,
  onChangeTextField,
  filterOptionsParams,
  formatDataBeforeOnChange,
  ...props
}) => {
  const filter = createFilterOptions<any>({
    stringify: (option) => get(option, optionName),
  });

  return (
    <Stack spacing={0.5}>
      <Typography variant="body14rg" sx={{ color: 'base.500' }}>{label}</Typography>
      <MuiAutocomplete
        {...props}
        size="small"
        value={multiple ? value || [] : value || null}
        multiple={multiple}
        options={options || []}
        // @ts-ignore
        filterOptions={(existingOptions, params) => {
          const currentOption = filterOptionsParams?.(params) || params;
          const filtered = filter(existingOptions, currentOption);
          if (freeSolo) {
            const { inputValue } = params;
            const isExisting = existingOptions.some(
              (option) => inputValue === get(option, optionName),
            );
            if (inputValue !== '' && !isExisting) {
              const newOption = set(
                { newOption: `Добавить ${inputValue}`, id: undefined },
                optionName,
                inputValue,
              );
              filtered.push(newOption);
            }
          }
          return filtered;
        }}
        disabled={disabled}
        loading={loading}
        freeSolo={freeSolo}
        loadingText="Загрузка..."
        noOptionsText="Нет данных"
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || label}
            helperText={error?.message}
            error={!!error}
            inputProps={{
              ...params.inputProps,
              onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                if (onChangeTextField) {
                  const newEvent = onChangeTextField(event);
                  params.inputProps.onChange?.(newEvent);
                } else params.inputProps.onChange?.(event as React.ChangeEvent<HTMLInputElement>);
              },
            }}
          />
        )}
        popupIcon={<KeyboardArrowDown sx={{ color: 'base.200', width: 24, height: 24 }} />}
        renderOption={(optionProps, option) => {
          const text = option?.newOption || getOptionLabel(option) || get(option, optionName);
          return (
            <li {...optionProps} key={`${option[equalityParam]}-${text}`}>
              {text}
            </li>
          );
        }}
        isOptionEqualToValue={(option, inputValue) => option[equalityParam] === (inputValue[equalityParam] || '')}
        onChange={(_, data, reason: string) => {
          if (reason === 'reset') onChange(null);
          else {
            const newData = formatDataBeforeOnChange?.(data) || data;
            onChange(newData);
          }
        }}
        onInputChange={onInputChange}
      />
    </Stack>
  );
};

export default Autocomplete;

Autocomplete.defaultProps = {
  equalityParam: 'id',
  optionName: 'displayName',
  multiple: false,
  disabled: false,
  onInputChange: undefined,
  placeholder: undefined,
  loading: false,
  getOptionLabel: undefined,
  freeSolo: false,
  options: [],
  label: undefined,
  error: undefined,
  limitTags: undefined,
  defaultValue: undefined,
  onChangeTextField: undefined,
  filterOptionsParams: undefined,
  formatDataBeforeOnChange: undefined,
};

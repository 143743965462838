import Box from '@mui/material/Box';
import BgImg2 from 'assets/images/auth-img-2.svg';
import BackgroundImg from 'assets/images/auth-background.png';
import BgImg1 from 'assets/images/auth-img-1.png';
import React, { FC } from 'react';
import Container from '@mui/material/Container';

type AuthContainerProps = {
  children: React.ReactNode
};

const AuthContainer: FC<AuthContainerProps> = ({ children }) => (
  <Container
    disableGutters
    maxWidth={false}
    sx={{
      backgroundImage: `url(${BgImg2}), url(${BackgroundImg})`,
      backgroundPosition: '100% bottom,42% 40%',
      backgroundSize: '40% 74%,140% 387.792%',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      width: '100%',
      minHeight: '100%',
      py: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '& > div': {
        zIndex: 1,
      },
    }}
  >
    <Box
      component="img"
      src={BgImg1}
      sx={{
        position: 'absolute', top: 0, left: 0, height: 'auto', width: '33vw', zIndex: 0,
      }}
    />
    {children}
  </Container>
);

export default AuthContainer;

import React, { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import Autocomplete, { AutocompleteProps } from 'components/inputs/Autocomplete';

interface AutocompleteControlProps extends Omit<AutocompleteProps, 'value' | 'onChange'> {
  name: string;
  rules?: UseControllerProps['rules'];
}
const AutocompleteControl: FC<AutocompleteControlProps> = ({
  name,
  rules,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <Autocomplete value={field.value} error={error} onChange={field.onChange} {...props} />
  );
};

export default AutocompleteControl;

AutocompleteControl.defaultProps = {
  rules: undefined,
};

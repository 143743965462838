import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import Form from 'components/form/Form';
import TextFieldControl from 'components/form/TextFieldControl';
import DatePickerControl from 'components/form/DatePickerControl';
import AutocompleteControl from 'components/form/AutocompleteControl';
import { ORGANIZATION_STATES, ORGANIZATION_TYPES } from 'constants/company';
import { CompanyRegisterForm } from 'pages/Register/types';
import useDaData, { DaDataResponse, DaDataSuggestion } from 'hooks/useDaData';
import { debounce } from 'lodash';
import Autocomplete from 'components/inputs/Autocomplete';
import { fromMillisecondsToDate } from 'helpers/date';

interface CompanyFormProps {
  form: UseFormReturn<CompanyRegisterForm>;
  isDaDataInput?: boolean;
  disableAllFields?: boolean;
  showRequestToChangeData?: boolean;
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  form, isDaDataInput, disableAllFields, showRequestToChangeData,
}) => {
  const { reset } = form;
  const [daDataOptions, setDaDataOptions] = useState<DaDataResponse['suggestions'] | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<DaDataSuggestion | undefined>();

  const { getDaData, loading } = useDaData();

  const onInnChanged = useCallback(debounce((_: SyntheticEvent<Element, Event>, value: string) => {
    getDaData({
      query: value,
    }, setDaDataOptions);
  }, 500), []);

  const handleSelectCompany = (company: DaDataSuggestion) => {
    setSelectedCompany(company);
    if (company) {
      const {
        name, kpp, ogrn, okpo,
        state, okato, oktmo, address,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ogrn_date, type, management, inn,
      } = company.data;
      reset({
        kpp,
        ogrn,
        okpo,
        okato,
        oktmo,
        inn,
        fullName: name.full_with_opf,
        shortName: name.short_with_opf,
        ogrnDate: fromMillisecondsToDate(ogrn_date),
        type: ORGANIZATION_TYPES.find(
          (organizationType) => organizationType.id === type,
        ),
        managerFio: management?.name,
        managerPosition: management?.post,
        registrationAddress: address.value,
        registrationDate: fromMillisecondsToDate(state.registration_date),
        state: ORGANIZATION_STATES.find(
          (organizationState) => organizationState.id === state.status,
        ),
      });
    } else {
      reset({
        kpp: undefined,
        ogrn: undefined,
        okpo: undefined,
        okato: undefined,
      });
    }
  };

  return (
    <Form form={form}>
      <Grid container columnSpacing={3.5} rowSpacing={3.5}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3.5}>
            {isDaDataInput
              ? (
                <Autocomplete
                  value={selectedCompany}
                  onChange={handleSelectCompany}
                  loading={loading}
                  onInputChange={onInnChanged}
                  label="ИНН"
                  optionName="data.inn"
                  equalityParam="value"
                  options={daDataOptions || []}
                  disabled={disableAllFields}
                  getOptionLabel={(option: DaDataResponse['suggestions'][number]) => `${option.data.inn} (${option.value})`}
                />
              )
              : <TextFieldControl label="ИНН" name="inn" rules={{ required: true }} disabled={disableAllFields} />}
            <TextFieldControl label="Полное наименование" multiline name="fullName" rules={{ required: true }} disabled={disableAllFields} />
            <TextFieldControl label="Краткое наименование" name="shortName" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="КПП" name="kpp" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="ОГРН" name="ogrn" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <DatePickerControl name="ogrnDate" label="Дата выдачи ОГРН" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <AutocompleteControl name="type" label="Тип организации" options={ORGANIZATION_TYPES} rules={{ required: true }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="ФИО руководителя" name="managerFio" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="Должность руководителя" name="managerPosition" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3.5}>
            <TextFieldControl label="ОКПО" name="okpo" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="ОКАТО" name="okato" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="ОКТМО" name="oktmo" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="Адрес организации" name="registrationAddress" multiline rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <DatePickerControl name="registrationDate" label="Дата регистрации" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <AutocompleteControl options={ORGANIZATION_STATES} label="Статус организации" name="state" rules={{ required: !isDaDataInput }} disabled={isDaDataInput || disableAllFields} />
            <TextFieldControl label="Ваша должность в организации" name="userPosition" rules={{ required: !isDaDataInput }} disabled={disableAllFields} />
            {showRequestToChangeData && (
              <Button sx={{ alignSelf: 'end', textDecoration: 'underline' }}>
                Запрос на изменение данных
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompanyForm;

import { Grade } from '@generated/graphql';

const getGradeByScore = (totalScore: number): Grade => {
  if (totalScore >= 0 && totalScore < 4) return Grade.Junior;
  if (totalScore >= 4 && totalScore < 7) return Grade.Middle;
  // if (totalScore >= 7 && totalScore < 9) return 'Middle+';
  return Grade.Senior;
};

const getScoreSummary = (score: number): string => {
  if (score >= 0 && score <= 3.3) return 'низкая оценка';
  if (score > 3.3 && score <= 6.6) return 'средняя оценка';
  return 'высокая оценка';
};

export { getGradeByScore, getScoreSummary };

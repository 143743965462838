import Box from '@mui/material/Box';
import React from 'react';

const BulletList = ({ children }: { children: React.ReactNode }) => (
  <Box
    component="ul"
    sx={(theme) => ({
      my: 0,
      pl: 2,
      '& li': {
        mb: 0.5,
        ...theme.typography.body14rg,
      },
      '& > li::marker': { color: 'primary.main' },
      '& ul': {
        my: 0.5,
      },
    })}
  >
    {children}
  </Box>
);

export default BulletList;

import React from 'react';
import TabLayout from 'layout/TabLayout';
import {
  Exact, TopicQuery,
} from '@generated/graphql';
import { QueryResult } from '@apollo/client';
import QuestionList from './components/QuestionList';

export type QuestionsProps = {
  topicQuery: QueryResult<TopicQuery, Exact<{ topicId: string }>>;
};

const Questions = ({ topicQuery }: QuestionsProps) => {
  const TABS = [
    {
      id: 'confirmed',
      title: 'Подтвержденные вопросы',
      component: (<QuestionList
        isConfirmed
        topicQuery={topicQuery}
      />),
    },
    {
      id: 'unconfirmed',
      title: 'Неподтвержденные вопросы',
      component: (<QuestionList
        topicQuery={topicQuery}
      />),
    },
  ];
  return (
    <TabLayout tabs={TABS} defaultTab="confirmed" />
  );
};

export default Questions;

import React from 'react';
import { useController } from 'react-hook-form';
import Select, { SelectCustomProps } from 'components/inputs/Select';

const SelectControl = ({
  items, name, rules, sx, ...props
}: {
  name: string;
  rules?: any;
} & Omit<SelectCustomProps, 'value' | 'onChange'>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <Select
      items={items}
      value={field.value}
      onChange={field.onChange}
      error={error}
      sx={sx}
      {...props}
    />
  );
};

export default SelectControl;

SelectControl.defaultProps = {
  rules: undefined,
};

import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import CompetencyMapsList from 'pages/CompetencyMaps/list';
import CompetenciesList from 'pages/Competencies/list';
import TestingList from 'pages/Testing/list';
import CompetencyMapView from 'pages/CompetencyMaps/view';
import CompetenceView from 'pages/Competencies/view';
import TopicView from 'pages/Topic/view';
import TestingView from 'pages/Testing/view';
import Login from 'pages/Login';
import ROUTES from 'constants/routes';
import UserRegister from 'pages/Register/UserRegister';
import CompanyRegister from 'pages/Register/CompanyRegister';
import CompanyPage from 'pages/CompanyPage';
import ProfilePage from 'pages/Profile';
import MainLayout from './layout/MainLayout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.USER_REGISTER,
        element: <UserRegister />,
      },
      {
        path: ROUTES.COMPANY_REGISTER,
        element: <CompanyRegister />,
      },
      {
        path: ROUTES.COMPANY,
        element: <CompanyPage />,
      },
      {
        path: ROUTES.PROFILE,
        element: <ProfilePage />,
      },
      {
        path: ROUTES.COMPETENCE_MAPS,
        children: [
          {
            index: true,
            element: <CompetencyMapsList />,
          },
          {
            path: 'view/:mapId',
            element: <CompetencyMapView />,
          },
          {
            path: 'create',
            element: <CompetencyMapView />,
          },
        ],
      },
      {
        path: ROUTES.COMPETENCIES,
        children: [
          {
            index: true,
            element: <CompetenciesList />,
          },
          {
            path: 'view/:competenceId',
            element: <CompetenceView />,
          },
          {
            path: 'create',
            element: <CompetenceView />,
          },
        ],
      },
      {
        path: ROUTES.TESTING,
        children: [
          {
            index: true,
            element: <TestingList />,
          },
          {
            path: 'view/:trialId',
            element: <TestingView />,
          },
          {
            path: 'create',
            element: <TestingView />,
          },
        ],
      },
      {
        path: ROUTES.TOPIC,
        children: [
          {
            path: 'view/:topicId',
            element: <TopicView />,
          },
          {
            path: 'create',
            element: <TopicView />,
          },
        ],
      },
    ],
  },
]);

export default router;

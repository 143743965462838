import Box from '@mui/material/Box';
import TemplateIcon from 'assets/icons/TemplateIcon';
import { Tooltip } from '@mui/material';
import React from 'react';

const TemplateMark = () => (
  <Tooltip title="Является шаблоном">
    <Box sx={{
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: 'blue',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <TemplateIcon sx={{ color: 'white', width: 12, height: 16 }} />
    </Box>
  </Tooltip>
);

export default TemplateMark;

import {
  ApolloClient, ApolloError, ApolloLink, createHttpLink, from, InMemoryCache, Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import cookies from 'js-cookie';

import { removeAuthCookies, setAuthCookies } from 'helpers/cookieHelper';
import ROUTES from 'constants/routes';

const graphqlLink = `${process.env.REACT_APP_API_URL}/graphql`;

const httpLink = createHttpLink({
  uri: graphqlLink,
  fetchOptions: {
    credentials: 'include',
  },
  credentials: 'include',
  headers: { 'Apollo-Require-Preflight': 'true' },
});
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = cookies.get('accessToken');
  const companyId = cookies.get('companyId');

  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : null,
      'company-id': companyId,
    },
  });

  return forward(operation);
});

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

const processQueue = async (error: ApolloError | null | unknown, token = null) => {
  failedRequestsQueue.forEach((prom) => (error ? prom.reject(error) : prom.resolve(token)));
  failedRequestsQueue = [];
  isRefreshing = false;
};

const refreshAccessToken = async () => {
  const accessToken = cookies.get('accessToken');
  const refreshToken = cookies.get('refreshToken');

  if (accessToken && refreshToken) {
    const r = await fetch(graphqlLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify({
        query: `mutation refreshTokens {refreshTokens(refreshToken: "${refreshToken}") {
                                            accessToken
                                            refreshToken
                                        }}`,
      }),
    });
    return r.json();
  }
  return null;
};

// eslint-disable-next-line consistent-return
const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
// eslint-disable-next-line consistent-return
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);

  const isMeOperation = operation.operationName === 'me';
  const isUserCompaniesOperation = operation.operationName === 'userCompanies';
  const isCompanyOperation = operation.operationName === 'company';
  const isUnauthorized = graphQLErrors?.some(({ message }) => message === 'Unauthorized');
  const isLoginByCredentialsOperation = operation.operationName === 'loginByCredentials';
  const isLoginPage = window.location.pathname.includes(ROUTES.LOGIN);
  const isUserRegisterPage = window.location.pathname.includes(ROUTES.USER_REGISTER);

  if (isUnauthorized && !isLoginByCredentialsOperation
    && ((!isMeOperation && !isUserCompaniesOperation && !isCompanyOperation)
      || ((isMeOperation || isUserCompaniesOperation || isCompanyOperation)
        && !(isLoginPage || isUserRegisterPage)))

  ) {
    // @ts-ignore
    return new Observable(async (observer) => {
      // Кладем запрос в очередь
      // отклоненных запросов, там он будет ждать решения по обновлению токена
      new Promise((resolve, reject) => {
        failedRequestsQueue.push({ resolve, reject });
      })
        .then(() => {
          // Если все ок, то идем дальше, пуская вперед остальные запросы;
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          forward(operation).subscribe(subscriber);
        })
        .catch(() => {
          // Refresh-токен тоже просрочен,
          // редирект на авторизацию произведет первый запрос в очереди отклоненных
        });
      // Если данный запрос первый в очереди отклоненных,
      // то есть до него никто не поставил isRefreshing
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Идем вручную на рефреш токена, ибо клиент Apollo
          // испорчен старым токеном до момента обновления
          const data = await refreshAccessToken();
          // Если токен не получилось обновить, идем на авторизацию
          if (data.errors?.length || !data) {
            throw new Error('Error refreshing token');
          }
          // Если все ок, обновляем токен
          const {
            data: {
              refreshTokens: { accessToken, refreshToken },
            },
          } = data;

          const newCookies = [
            {
              name: 'accessToken',
              value: accessToken,
            },
            {
              name: 'refreshToken',
              value: refreshToken,
            },
          ];

          setAuthCookies(newCookies);
          // Запускаем очередь отклоненных запросов с новым токеном
          await processQueue(null, accessToken);
        } catch (e) {
          await processQueue(e, null);
          // Аналогично ошибкам GQL, если не достучались до сервера вообще, идем на авторизацию

          removeAuthCookies(['accessToken', 'refreshToken', 'companyId']);

          let redirectUrl = '';

          if (window.location.pathname && window.location.pathname !== '/') {
            redirectUrl += window.location.pathname;
          }

          if (window.location.search) {
            redirectUrl += window.location.search;
          }

          console.log(window.location.pathname, window.location.search);

          window.location.href = `/${ROUTES.LOGIN}${redirectUrl ? `?redirectUrl=${encodeURIComponent(
            redirectUrl,
          )}` : ''}`;
        }
      }
    });
  }
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(),
});

export default client;

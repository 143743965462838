import React, { useContext, useState } from 'react';
import {
  Card, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { FieldValues, useFieldArray, UseFormReturn } from 'react-hook-form';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SelectControl from 'components/form/SelectControl';
import TextFieldControl from 'components/form/TextFieldControl';
import CardHeader from 'components/CardHeader';
import { get } from 'lodash';
import { ConfirmModalContext } from 'context/confirmModalContext';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import AddCompetenceModal from './AddCompetenceModal';

type NameType<T extends FieldValues> = T['knowledgeBlock'][number];

type KnowledgeBlockProps<T extends FieldValues> = {
  onRemoveBlock: () => void;
  name: NameType<T>;
  isEdited: boolean;
  form:UseFormReturn<T>;
};

const KnowledgeBlock = <T extends FieldValues>({
  onRemoveBlock, name, isEdited, form,
}:KnowledgeBlockProps<T>) => {
  const navigate = useNavigate();
  const { handleOpen } = useContext(ConfirmModalContext);

  const [openAddCompetence, setAddCompetence] = useState(false);

  const { control, watch, formState: { errors } } = form;

  const competenciesName: NameType<T>['knowledgeBlockCompetences'] = `${name}.knowledgeBlockCompetences`;
  const fieldArray = useFieldArray({
    control,
    name: competenciesName,
    keyName: 'formId',
  });
  const { fields, append, remove } = fieldArray;

  const addCompetency = (competence: NameType<T>['knowledgeBlockCompetences']) => {
    append(competence);
    setAddCompetence(false);
  };
  const isError = !!get(errors, competenciesName)?.root?.message;

  const title = watch(`${name}.title` as NameType<T>['title']);

  const onRemoveCompetence = (index: number) => {
    handleOpen({
      title: 'Вы уверены, что хотите удалить компетенцию?',
      handleClick: () => remove(index),
    });
  };

  return (
    <Card
      sx={{
        height: '100%', minWidth: 295, maxWidth: 295, border: isError ? '1px solid' : undefined, borderColor: 'error.main',
      }}
      component={Stack}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }} alignItems="center">
        {
          isEdited ? <TextFieldControl placeholder="Название" name={`${name}.title`} size="small" fullWidth rules={{ required: true }} />
            : (<CardHeader title={title} />)
        }
        {isEdited && (
        <IconButton
          onClick={onRemoveBlock}
          component={Close}
          sx={{
            fontSize: 34, color: 'base.200', right: 0, top: 'center',
          }}
        />
        )}
      </Stack>
      <Divider sx={{ mt: 1.5, mb: 2 }} />
      <Stack spacing={2} sx={{ overflow: 'auto' }}>
        {fields?.length
          ? fields.map((field, index) => {
            const topics = watch(`${competenciesName}.${index}.topics` as NameType<T>['knowledgeBlockCompetences'][number]['topics']);
            return (
              <Stack direction="row" spacing={1.5} justifyContent="space-between" alignItems="center">
                <SelectControl
                  items={topics || []}
                  labelName="title"
                  valueName="id"
                  readOnly
                  name={`knowledgeBlockCompetences.${index}`}
                  key={field.formId}
                  sx={{ backgroundColor: 'bgSwitch' }}
                  displayEmpty
                  textColor="primary"
                  renderValue={(selected: any) => {
                    if (!selected?.length) return watch(`${competenciesName}.${index}.title` as NameType<T>['knowledgeBlockCompetences'][number]['title']);
                    return selected?.[0];
                  }}
                  fullWidth
                  onItemClick={(item) => navigate(`/${ROUTES.TOPIC}/view/${item.id}`)}
                />
                {isEdited && (
                <IconButton
                  onClick={() => onRemoveCompetence(index)}
                  component={Close}
                  sx={{
                    fontSize: 34, color: 'base.200',
                  }}
                />
                )}
              </Stack>
            );
          })
          : <Typography variant="body14rg" sx={{ color: 'text.disabled' }}>Добавьте компетенции</Typography>}
      </Stack>
      <Box flex={1} />
      {isEdited && (
      <Button
        startIcon={<Add />}
        variant="contained"
        color="secondary"
        onClick={() => setAddCompetence(true)}
        sx={{
          mt: 2,
          width: 'max-content',
          backgroundColor: 'bgSwitch',
          '&:hover': { backgroundColor: 'bgSwitch' },
          '&:active': { backgroundColor: 'bgSwitch' },
          '&.Mui-disabled': { backgroundColor: 'bgSwitch' },
        }}
      >
        Компетенция
      </Button>
      )}
      {
        openAddCompetence && (
        <AddCompetenceModal
          open={openAddCompetence}
          onClose={() => setAddCompetence(false)}
          onAdd={addCompetency}
        />
        )
      }
    </Card>
  );
};

export default KnowledgeBlock;

import { Tooltip, Typography } from '@mui/material';
import React, { useRef } from 'react';

export const useIsOverflow = (ref: any, callback?: any) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.offsetWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};

const CardHeader = ({ title }: { title: string }) => {
  const ref = useRef();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isOverflow = useIsOverflow(ref);

  return (
    <Tooltip title={title}>
      {/* @ts-ignore */}
      <Typography
        ref={ref}
        variant="h20"
        sx={{
          display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', height: 30, whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};

export default CardHeader;

import {
  Card, Chip, Divider, Grid, Stack, Tooltip, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { formatNumber, gradeColor } from 'helpers/index';
import { formatISODate } from 'helpers/date';
import React from 'react';
import CardHeader from 'components/CardHeader';
import { Grade } from '@generated/graphql';
import ROUTES from 'constants/routes';

export type TopicCardProps = {
  id: string,
  title: string,
  createdAt: any,
  updatedAt: any,
  grades?: Array<Grade> | null,
  confirmedQuestionsCount?: number | null,
  competence: { id: string, title: string }
};

const TopicCard = ({
  topic,
} : { topic: TopicCardProps }) => {
  const {
    grades, title, confirmedQuestionsCount, id, createdAt, competence, updatedAt,
  } = topic;
  return (
    <Card
      component={Link}
      to={`/${ROUTES.TOPIC}/view/${id}`}
      sx={{
        display: 'block', height: '100%', textDecoration: 'none', justifyContent: 'space-between',
      }}
    >
      <Box mb={2.3}>
        <CardHeader title={title} />
        <Divider sx={{ mt: 1.5 }} />
      </Box>
      <Box>
        <Stack spacing={2.3} mb={3.5}>
          {
              !!grades?.length
              && (
                <Box>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                    {grades.map((grade) => (
                      <Grid item>
                        <Chip
                          label={grade}
                          sx={{ backgroundColor: `${gradeColor(grade)}.light`, color: `${gradeColor(grade)}.contrast` }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>

              )
            }
          <Typography variant="body16rg" sx={{ '& > span': { color: 'primary.main', fontWeight: 700 } }}>
            <span>{confirmedQuestionsCount}</span>
            {' '}
            {formatNumber(['вопрос', 'вопроса', 'вопросов'], confirmedQuestionsCount, true)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1.5} alignItems="end">
          <Tooltip title={`Компетенция ${competence.title}`}>
            <Box sx={{
              borderRadius: 1,
              backgroundColor: 'bgSwitch',
              color: 'text.primary',
              py: 0.5,
              px: 1.5,
              overflow: 'hidden',
              height: 32,
            }}
            >
              <Typography
                variant="body16rg"
                sx={{
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {competence.title}
              </Typography>
            </Box>
          </Tooltip>
          <Stack justifyContent="space-between" spacing={0.5}>
            <Typography
              variant="body14rg"
              sx={{
                '& > span':
                    { color: 'text.disabled' },
                display: 'inline-flex',
                justifyContent: 'space-between',
              }}
            >
              <span>созд.</span>
              {' '}
              {createdAt && formatISODate(createdAt, 'dd.MM.yy')}
            </Typography>
            <Typography
              variant="body14rg"
              sx={{
                '& > span':
                    { color: 'text.disabled' },
                display: 'inline-flex',
                justifyContent: 'space-between',
              }}
            >
              <span>ред.</span>
              {' '}
              {updatedAt && formatISODate(updatedAt, 'dd.MM.yy')}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default TopicCard;

import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import markdownit from 'markdown-it';
import hljs from 'highlight.js';

const md = markdownit({
  highlight(str: string, lang: string): string {
    let highlighted = '';
    if (lang && hljs.getLanguage(lang)) {
      try {
        highlighted = hljs.highlight(str, { language: lang, ignoreIllegals: true }).value;
      } catch (error) {
        console.error('Highlighting error:', error);
      }
    }

    // Экранирование HTML-символов в случае ошибки или отсутствия подсветки
    if (!highlighted) {
      highlighted = md.utils.escapeHtml(str);
    }

    return `<pre><code class="hljs">${highlighted}</code></pre>`;
  },
});

interface MarkdownFormatterProps extends TypographyProps {
  text: string;
}

const MarkdownFormatter: React.FC<MarkdownFormatterProps> = ({ text, ...props }) => {
  // Заменяем экранированные символы новой строки на реальные символы новой строки
  const normalizedText = text.replace(/\\n/g, '\n');

  return (
    <Typography
      {...props}
      dangerouslySetInnerHTML={{ __html: md.render(normalizedText) }}
    />
  );
};

export default MarkdownFormatter;

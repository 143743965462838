import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import HomeIcon from 'assets/icons/HomeIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as React from 'react';
import ROUTES from 'constants/routes';

const PAGES = [
  { title: 'Карты компетенций', link: `/${ROUTES.COMPETENCE_MAPS}` },
  { title: 'Компетенции', link: `/${ROUTES.COMPETENCIES}` },
  { title: 'Тестирования', link: `/${ROUTES.TESTING}` }];

const Navigation = ({ isAuth }: { isAuth: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // TODO
  const isDirector = isAuth;

  return (
    <>
      {
        isDirector && (
          <Stack direction="row" alignItems="center">
            <Button
              size="medium"
              sx={{ textAlign: 'left' }}
              disableRipple
              startIcon={<HomeIcon />}
              onClick={() => navigate(`/${ROUTES.COMPANY}`)}
            >
              Моя компания
            </Button>
          </Stack>
        )
      }
      {isAuth && PAGES.map((page) => (
        <Button
          color="secondary"
          size="medium"
          disableRipple
          key={page.link}
          sx={{
            ...(page.link === location.pathname && { color: 'primary.dark' }),
          }}
          component={Link}
          to={page.link}
        >
          {page.title}
        </Button>
      ))}
    </>
  );
};

export default Navigation;

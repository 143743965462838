import React, { useContext } from 'react';
import { Card, Grid } from '@mui/material';
import ProfileForm from 'pages/Profile/components/ProfileForm';
// import Invitations from 'pages/Profile/components/Inventations';
import Companies from 'pages/Profile/components/Companies';
import { UserContext } from 'context/userContext';
import CircularLoading from 'components/CircularLoading';
import Box from '@mui/material/Box';
import PeopleImg from 'assets/images/people-img.svg';

const ProfilePage = () => {
  const {
    me, meLoading, userCompaniesLoading, userCompanies,
  } = useContext(UserContext);

  if (meLoading || userCompaniesLoading || !me) return <CircularLoading />;

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Card sx={{ px: 5, py: 3.5, borderRadius: 3 }}>
          <ProfileForm user={me} />
          {/* { TODO */}
          {/*  user.invites?.length > 0 && ( */}
          {/*    <Invitations {...{ user, setUser }} /> */}
          {/*  ) */}
          {/* } */}
          {
           userCompanies && userCompanies?.length > 0 && (
           <Companies companies={userCompanies} />
           )
          }
        </Card>
      </Grid>
      <Grid md={6} display={{ xs: 'none', md: 'block' }}>
        <Box
          sx={{
            height: '100%',
            width: '50vw',
            backgroundImage: `url(${PeopleImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '112%',
            backgroundPosition: '106% 19%',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProfilePage;

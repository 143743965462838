import React, { FormHTMLAttributes, ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

export type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  children: ReactNode;
};

const Form = <T extends FieldValues>(props: FormHTMLAttributes<HTMLFormElement> & FormProps<T>) => {
  const {
    form, children, onSubmit, ...rest
  } = props;
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={(e) => {
          if (typeof onSubmit === 'function') onSubmit(e);
          e.preventDefault();
        }}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;

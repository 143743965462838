import React from 'react';
import {
  Grid,
  Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { groupAnswers, TrialAnswersType } from 'pages/Testing/view/components/ResultTab';
import { Check, Close } from '@mui/icons-material';
import CollapsedBlock from '../CollapsedBlock';

interface AnswerStatisticsProps {
  answers: TrialAnswersType
}

const AnswerStatistics = ({ answers }:
AnswerStatisticsProps) => {
  const groupedByBlock = answers ? Object.entries(groupAnswers(answers, 'blocks')) : undefined;
  return (
    <CollapsedBlock title="Статистика ответов">
      <Grid item xs={12} lg={6}>
        <Table>
          {groupedByBlock
            ? groupedByBlock.map((byBlock, blockIndex) => {
              const blocks = byBlock[1][0].question.topic.competence.CompetenceKnowledgeBlocks?.map((map: { knowledgeBlock: { title: any; }; }) => map.knowledgeBlock.title).join(', ');
              return (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {`${blockIndex + 1}. ${blocks}`}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {
                      Object.entries(groupAnswers(byBlock[1], 'competence')).map((byCompetence) => (
                        <TableBody>
                          <TableRow>
                            <TableCell variant="head">
                              {byCompetence[1][0].question.topic.competence.title}
                            </TableCell>
                            <TableCell colSpan={2} sx={{ textAlign: 'center' }} variant="head">
                              Баллы
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} variant="head">
                              Время
                            </TableCell>
                          </TableRow>
                          {
                            byCompetence[1].map((answer) => (
                              <TableRow>
                                <TableCell>
                                  {answer.question.topic.title}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                  {answer.score?.toFixed()}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                  {answer.score! > 40 ? <Check color="success" /> : <Close color="error" />}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                  {answer.time ? new Date(answer.time * 1000).toISOString().substring(11, 19) : '00:00:00'}
                                </TableCell>
                              </TableRow>
                            ))
                          }

                        </TableBody>

                      ))
                    }

                </>
              );
            })
            : <Typography>Нет ответов на вопросы</Typography>}
        </Table>
      </Grid>
    </CollapsedBlock>

  );
};
export default AnswerStatistics;

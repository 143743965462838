import { useController, UseControllerProps } from 'react-hook-form';
import {
  TextField, TextFieldProps, InputAdornment, Typography, Stack, IconButton,
} from '@mui/material';
import {
  FC, ReactNode, ForwardedRef, useState,
} from 'react';
import * as React from 'react';
import { IMask, IMaskInput } from 'react-imask';
import OpenEyeIcon from 'assets/icons/OpenEyeIcon';
import CloseEyeIcon from 'assets/icons/CloseEyeIcon';

type IProps = {
  name: string;
  endIcon?: ReactNode;
  handleChange?: (value: string, onChange: any) => any;
  isError?: boolean;
  label?: string;
  maskType?: 'time';
  maskProps?: { mask: string };
};

const MaskCustom = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      unmask
    />
  );
});

const TextFieldControl: FC<Omit<TextFieldProps, 'label'> & IProps & UseControllerProps> = ({
  name,
  placeholder,
  variant,
  size,
  rules,
  handleChange,
  label,
  maskProps,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ defaultValue: '', name, rules });

  const [showPassword, setShowPassword] = useState(false);

  const isPassword = props.type === 'password';

  const generateEndIcon = () => {
    if (isPassword) {
      return (
        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
          {showPassword ? <OpenEyeIcon sx={{ color: 'base.200' }} /> : <CloseEyeIcon sx={{ color: 'base.200' }} />}
        </IconButton>
      );
    }
    if (props.endIcon) return props.endIcon;
    return undefined;
  };
  const endIcon = generateEndIcon();

  const isTimeInput = props.maskType === 'time';

  const generateInputProps = () => {
    if (isTimeInput) {
      return {
        mask: 'HH:MM',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2,
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2,
          },
        },
      };
    }
    if (maskProps) return maskProps;
    return {};
  };

  const inputProps = generateInputProps();

  const getInputType = () => {
    if (isPassword) {
      if (!showPassword) return 'password';
      return 'text';
    }
    return props.type;
  };

  const inputType = getInputType();

  return (
    <Stack spacing={0.5} sx={{ width: props.fullWidth ? '100%' : undefined }}>
      {label && <Typography variant="body14rg" sx={{ color: 'base.500' }}>{label}</Typography>}
      <TextField
        {...props}
        {...field}
        onChange={(e) => {
          const { value } = e.target;
          if (handleChange) handleChange(value, field.onChange);
          else field.onChange(value);
        }}
        size={size}
        error={!!error || props.isError}
        fullWidth
        placeholder={placeholder || label}
        type={inputType}
        variant={variant}
        helperText={error?.message}
        InputProps={{
          endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : undefined,
          inputProps,
          inputComponent: maskProps?.mask || isTimeInput ? MaskCustom : undefined,
        }}
      />
    </Stack>

  );
};

export default TextFieldControl;

TextFieldControl.defaultProps = {
  endIcon: undefined,
  handleChange: undefined,
  isError: false,
  label: undefined,
  maskType: undefined,
  maskProps: undefined,
};

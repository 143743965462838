import Avatar from '@mui/material/Avatar';
import { getInitials } from 'helpers/index';
import { IconButton, Stack, Typography } from '@mui/material';
import LogoutIcon from 'assets/icons/LogoutIcon';
import { removeAuthCookies } from 'helpers/cookieHelper';
import ROUTES from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { UserContext } from 'context/userContext';
import UserMenu from 'layout/MainLayout/components/UserMenu';
import Button from '@mui/material/Button';
import { CompanyContext } from 'context/companyContext';

const User = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const {
    me, meRefetch,
  } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);

  const userName = `${me?.lastName ?? ''} ${me?.firstName?.[0] ?? ''}.${me?.middleName?.[0] ? `${me?.middleName?.[0]}.` : ''}`.trim();

  const logout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    removeAuthCookies(['accessToken', 'refreshToken', 'companyId']);
    meRefetch().then(() => navigate(`/${ROUTES.LOGIN}`));
  };

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);

  return (
    <div>
      <Stack
        alignItems="center"
        direction="row"
        sx={{ cursor: 'pointer', textAlign: 'left' }}
        onClick={handleOpenMenuClick}
        component={Button}
      >
        <Avatar sx={{ width: 33, height: 33, mr: 0.8 }}>
          {getInitials(userName)}
        </Avatar>
        <Stack sx={{ mr: 1.5 }}>
          <Typography variant="body16rg">{userName}</Typography>
          <Typography
            sx={{
              color: 'primary.main', textWrap: 'nowrap', textOverflow: 'ellipsis', maxWidth: 140, overflow: 'hidden',
            }}
            variant="body14rg"
          >
            {currentCompany?.shortName}
          </Typography>
        </Stack>
        <IconButton onClick={logout}>
          <LogoutIcon sx={{ color: 'primary.main' }} />
        </IconButton>
      </Stack>
      <UserMenu
        open={openMenu}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default User;

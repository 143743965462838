const ROUTES = {
  LOGIN: 'login',
  USER_REGISTER: 'user-register',
  COMPANY_REGISTER: 'company-register',
  COMPANY: 'company',
  PROFILE: 'profile',
  COMPETENCE_MAPS: 'competence-maps',
  COMPETENCIES: 'competencies',
  TESTING: 'testing',
  TOPIC: 'topic',
};

export default ROUTES;

import React, { useContext } from 'react';
import ListLayout from 'layout/ListLayout';
import { PlatformTrialListOrderFields, OrderType, useTrialsQuery } from '@generated/graphql';
import useSetTabTitle from 'hooks/useSetTabTitle';
import { CompanyContext } from 'context/companyContext';
import { generateUnavailableText } from 'components/buttons/ButtonWithPermissions';
import TestingCard from './TestingCard';
import filterFields from './filterFields';

const TestingList = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const {
    data, loading, error, refetch,
  } = useTrialsQuery({
    variables: {
      page: 0,
      limit: 8,
      filter: {},
      order: {
        field: PlatformTrialListOrderFields.CreatedAt,
        order: OrderType.Asc,
      },
    },
  });
  const list = data?.trials.data;

  const createAssessmentUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, 'создания тестирования');

  useSetTabTitle('Тестирования кандидатов');

  return (
    <ListLayout
      createUnavailableText={createAssessmentUnavailableText}
      title="Тестирования кандидатов"
      filters={filterFields}
      CardComponent={TestingCard}
      list={list}
      loading={loading}
      error={error}
      refetch={refetch}
      totalCount={data?.trials.totalCount}
      emptyPlaceholderText="Пока нет тестирований"
      defaultOrder={{
        field: PlatformTrialListOrderFields.CreatedAt,
        order: 'desc',
      }}
      sortingList={[
        {
          buttonText: 'По дате создания',
          fieldName: PlatformTrialListOrderFields.CreatedAt,
        },
        {
          buttonText: 'По дате завершения',
          fieldName: PlatformTrialListOrderFields.CompletedAt,
        },
      ]}
    />
  );
};

export default TestingList;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.75 0C4.94891 0 5.13968 0.0790176 5.28033 0.21967C5.42098 0.360322 5.5 0.551088 5.5 0.75V2H10.5V0.75C10.5 0.551088 10.579 0.360322 10.7197 0.21967C10.8603 0.0790176 11.0511 0 11.25 0C11.4489 0 11.6397 0.0790176 11.7803 0.21967C11.921 0.360322 12 0.551088 12 0.75V2H13.25C14.216 2 15 2.784 15 3.75V14.25C15 14.7141 14.8156 15.1592 14.4874 15.4874C14.1592 15.8156 13.7141 16 13.25 16H2.75C2.28587 16 1.84075 15.8156 1.51256 15.4874C1.18437 15.1592 1 14.7141 1 14.25V3.75C1 2.784 1.784 2 2.75 2H4V0.75C4 0.551088 4.07902 0.360322 4.21967 0.21967C4.36032 0.0790176 4.55109 0 4.75 0ZM2.5 7.5V14.25C2.5 14.388 2.612 14.5 2.75 14.5H13.25C13.3163 14.5 13.3799 14.4737 13.4268 14.4268C13.4737 14.3799 13.5 14.3163 13.5 14.25V7.5H2.5ZM13.25 3.5H2.75C2.6837 3.5 2.62011 3.52634 2.57322 3.57322C2.52634 3.62011 2.5 3.6837 2.5 3.75V6H13.5V3.75C13.5 3.6837 13.4737 3.62011 13.4268 3.57322C13.3799 3.52634 13.3163 3.5 13.25 3.5Z"
        fill="currentColor"
      />
    </svg>

  </SvgIcon>
);

export default CalendarIcon;

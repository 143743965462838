import React, { FC } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import CalendarIcon from 'assets/icons/CalendarIcon';

type DatePickerProps = {
  name: string;
  label?: string;
  rules?: object;
  defaultValue?: string;
  disabled?: boolean;
};

const DatePickerControl: FC<DatePickerProps> = ({
  name, label, rules, defaultValue, disabled,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue });
  return (
    <Stack spacing={0.5}>
      <Typography variant="body14rg" sx={{ color: 'base.500' }}>{label}</Typography>
      <DatePicker
        value={field.value ? new Date(field.value) : null}
        onChange={field.onChange}
        views={['day', 'month', 'year']}
        format="dd.MM.yyyy"
        disabled={disabled}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!error,
          },
          openPickerButton: {
            sx: { color: 'base.200' },
          },
          openPickerIcon: {
            sx: { width: 16, height: 16 },
          },
        }}
      />
    </Stack>
  );
};

export default DatePickerControl;

DatePickerControl.defaultProps = {
  rules: undefined,
  defaultValue: undefined,
  disabled: false,
  label: undefined,
};

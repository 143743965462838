import React, { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'components/form/Form';
import { Card, Stack } from '@mui/material';
import TextFieldControl from 'components/form/TextFieldControl';
import {
  TopicQuery, useCompetenceOptionsQuery,
  useCreateOrUpdateTopicMutation, useDeleteTopicMutation,
} from '@generated/graphql';
import DatePickerControl from 'components/form/DatePickerControl';
import SaveEditButtons from 'components/buttons/SaveEditButtons';
import AutocompleteControl from 'components/form/AutocompleteControl';
import useIsCreate from 'hooks/useIsCreate';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectControl from 'components/form/SelectControl';
import qs from 'qs';
import { GRADE_OPTIONS } from 'constants/global';
import ROUTES from 'constants/routes';
import { ConfirmModalContext } from 'context/confirmModalContext';

type TopicInfoInputs = {
  id: string,
  title: string,
  createdAt: any,
  updatedAt: any,
  competence?: { id: string, title: string },
  topicGrades?: string[],
};

const Info = ({ topic }: { topic: TopicQuery['topic'] | null | undefined }) => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const {
    competenceId,
    competenceTitle,
  } = params as unknown as {
    competenceId?: string;
    competenceTitle?: string;
  };

  const { addToast } = useContext(ToastContext);
  const { handleOpen } = useContext(ConfirmModalContext);
  const navigate = useNavigate();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState(isCreate);

  const { data: competenceData, loading: competenceLoading } = useCompetenceOptionsQuery();

  const form = useForm<TopicInfoInputs>({
    values: {
      id: topic?.id!,
      title: topic?.title!,
      createdAt: topic?.createdAt,
      updatedAt: topic?.updatedAt,
      competence: isCreate && competenceId && competenceTitle
        ? { id: competenceId, title: competenceTitle }
        : topic?.competence,
      topicGrades: topic?.grades || [],
    },
  });
  const { handleSubmit } = form;

  const [updateTopic, { loading: updateLoading }] = useCreateOrUpdateTopicMutation();
  const onSubmit = (formData: TopicInfoInputs) => {
    updateTopic({
      variables: {
        input: {
          id: topic?.id,
          title: formData.title,
          competenceId: formData.competence?.id!,
        },
      },
    }).then((res) => {
      if (isCreate) navigate(`/${ROUTES.TOPIC}/view/${res.data?.createOrUpdateTopic.id}`);
      setIsEditing(false);
      addToast({ type: ToastTypeEnum.SUCCESS });
    }).catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const [deleteTopic] = useDeleteTopicMutation();

  const handleDeleteTopic = async () => {
    await deleteTopic({
      variables: {
        topicId: topic?.id!,
      },
    }).then(() => {
      addToast({ type: ToastTypeEnum.SUCCESS, text: 'Топик успешно удален' });
      navigate(`/${ROUTES.COMPETENCIES}/view/${topic?.competence.id}`);
    }).catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const handleOpenConfirmDeleteTopicModal = () => {
    handleOpen({
      title: 'Вы уверены, что хотите удалить топик?',
      actionButtonText: 'Удалить',
      handleClick: handleDeleteTopic,
    });
  };

  return (
    <Card>
      <Form form={form}>
        <Stack spacing={2.5}>
          <TextFieldControl name="title" label="Название" disabled={!isEditing} />
          <AutocompleteControl
            options={competenceData?.competences.data}
            name="competence"
            label="Компетенция"
            loading={competenceLoading}
            optionName="title"
            disabled={!isEditing}
          />
          <SelectControl
            items={GRADE_OPTIONS}
            name="topicGrades"
            label="Грейд"
            labelName="displayName"
            valueName="id"
            disabled
            multiple
            sx={{
              backgroundColor: 'bgSwitch',
            }}
          />
          <DatePickerControl name="createdAt" label="Дата создания" disabled />
          <DatePickerControl name="updatedAt" label="Дата изменения" disabled />
          <SaveEditButtons
            isEdited={isEditing}
            onSubmit={handleSubmit(onSubmit)}
            setIsEdited={setIsEditing}
            loading={updateLoading}
            deleteText="Удалить топик"
            onDelete={handleOpenConfirmDeleteTopicModal}
          />
        </Stack>
      </Form>
    </Card>
  );
};

export default Info;

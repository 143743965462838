import React, {
  Dispatch, SetStateAction, useContext,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Card, Stack, Typography,
} from '@mui/material';
import Form from 'components/form/Form';
import TextFieldControl from 'components/form/TextFieldControl';
import Button from '@mui/material/Button';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useLoginByCredentialsMutation } from '@generated/graphql';
import { setAuthCookies } from 'helpers/cookieHelper';

type SignInInputs = {
  email: string;
  password: string;
};

interface UserSignInProps {
  setStep: Dispatch<SetStateAction<number>>;
}

const UserLogin = ({ setStep }: UserSignInProps) => {
  const { addToast } = useContext(ToastContext);
  const form = useForm<SignInInputs>();
  const { handleSubmit, setError } = form;

  const [login] = useLoginByCredentialsMutation();

  const onSubmit = (formData: SignInInputs) => {
    login({
      variables: formData,
    }).then((res: any) => {
      const {
        data: {
          loginByCredentials: { accessToken, refreshToken },
        },
      } = res;
      setAuthCookies([{ name: 'accessToken', value: accessToken }, { name: 'refreshToken', value: refreshToken }]);
      setStep(1);
    })
      .catch(() => {
        const names:Array<keyof SignInInputs> = ['email', 'password'];
        names.forEach((name) => setError(name, { message: 'Неверный логин или пароль' }));
      });
  };

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  return (
    <Card sx={{
      flex: 1, px: 5, py: 4, '& > form': { height: '100%' }, maxWidth: 556,
    }}
    >
      <Typography variant="h28" mb={3.5} sx={{ display: 'block' }}>Авторизация</Typography>
      <Form form={form}>
        <Stack spacing={4}>
          <TextFieldControl name="email" label="Email" type="email" rules={{ required: true }} />
          <TextFieldControl name="password" label="Пароль" rules={{ required: true }} type="password" />
          <Button variant="contained" size="large" onClick={handleSubmit(onSubmit, onError)}>Войти</Button>
        </Stack>
      </Form>
    </Card>
  );
};

export default UserLogin;

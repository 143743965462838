import React, { useContext } from 'react';
import {
  Competence_Programing_Language,
  Grade, Question_Type,
  useGenerateQuestionsMutation,
  useQuestionSubtypesQuery,
} from '@generated/graphql';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Stack,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import Form from 'components/form/Form';
import TextFieldControl from 'components/form/TextFieldControl';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { LoadingButton } from '@mui/lab';
import AutocompleteControl from 'components/form/AutocompleteControl';
import { GRADE_OPTIONS, PROGRAMING_LANGUAGE_TITLE } from 'constants/global';
import SelectControl from 'components/form/SelectControl';
import { QUESTION_TYPES } from 'pages/Topic/view/components/UpdateQuestionModal';

type GenerateQuestionsModalProps = {
  open: boolean;
  onClose: () => void;
  competence: { id: string; title: string; programingLanguage: Competence_Programing_Language };
  topic: { id: string; title: string };
};

type GenerateQuestionsModalInputs = {
  competence: { id: string; title: string; programingLanguage: string };
  topic: string;
  grade?: { id: Grade; name: string };
  questionType: Question_Type;
  questionSubtype?: { id: string; displayName: string };
};

const GenerateQuestionsModal = ({
  open, onClose, competence, topic,
}: GenerateQuestionsModalProps) => {
  const { addToast } = useContext(ToastContext);
  const [generateQuestions, { loading }] = useGenerateQuestionsMutation();
  const {
    data: questionSubtypesData,
    loading: questionSubtypesLoading,
  } = useQuestionSubtypesQuery();
  const questionSubtypes = questionSubtypesData?.questionSubtypes
    .map((subtype) => ({
      id: subtype,
      displayName: subtype,
    }));
  const form = useForm<GenerateQuestionsModalInputs>({
    defaultValues: {
      competence: {
        id: competence?.id as string,
        title: competence?.title,
        programingLanguage: PROGRAMING_LANGUAGE_TITLE[competence?.programingLanguage],
      },
      topic: topic.title,
    },
  });

  const { handleSubmit, watch } = form;

  const onSubmit = (formData: GenerateQuestionsModalInputs) => {
    generateQuestions({
      variables: {
        input: {
          topicId: topic.id,
          grade: formData.grade?.id!,
          questionType: formData.questionType,
          questionSubtype: formData.questionSubtype?.id,
        },
      },
    }).then(() => {
      addToast({ type: ToastTypeEnum.SUCCESS, text: 'Генерация вопросов может занимать несколько минут' });
      onClose();
    }).catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const questionTypeWatch = watch('questionType');

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle variant="h24" textAlign="center">
        Генерация вопросов
      </DialogTitle>
      <DialogContent sx={{ mb: 3 }}>
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl name="competence.title" disabled label="Компетенция" />
            <TextFieldControl name="competence.programingLanguage" disabled label="Язык программирования" />
            <TextFieldControl name="topic" disabled label="Топик" />
            <AutocompleteControl
              options={GRADE_OPTIONS}
              name="grade"
              label="Грейд"
              rules={{
                required: true,
              }}
            />
            <SelectControl
              name="questionType"
              items={QUESTION_TYPES}
              sx={{ backgroundColor: 'bgSwitch' }}
              label="Тип вопроса"
              rules={{
                required: true,
              }}
            />
            {
              questionTypeWatch === Question_Type.Practical && (
                <AutocompleteControl
                  name="questionSubtype"
                  options={questionSubtypes || []}
                  loading={questionSubtypesLoading}
                  label="Подтип"
                  rules={{
                    // eslint-disable-next-line max-len
                    validate: (value, formValues) => !(formValues.questionType === Question_Type.Practical && !value),
                  }}
                />
              )
            }
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <LoadingButton variant="contained" onClick={handleSubmit(onSubmit, onError)} loading={loading}>
          Сгенерировать
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateQuestionsModal;

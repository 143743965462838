import React, { useContext } from 'react';
import {
  Divider, MenuItem, MenuList, Popover, popoverClasses, PopoverProps, Stack, Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import CurrentCompanyIcon from 'components/CurrentCompanyIcon';
import { UserContext } from 'context/userContext';
import { CompanyContext } from 'context/companyContext';

interface UserMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl: PopoverProps['anchorEl']
}

const UserMenu = ({
  open, onClose, anchorEl,
}: UserMenuProps) => {
  const navigate = useNavigate();
  const { userCompanies } = useContext(UserContext);
  const { currentCompanyId, handleSelectCompany } = useContext(CompanyContext);

  const handleClickCompany = (companyId: string) => {
    handleSelectCompany(companyId);
    onClose();
  };

  const handleProfileClick = () => {
    navigate(`/${ROUTES.PROFILE}`);
    onClose();
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        [`& .${popoverClasses.paper}`]: {
          p: 2.5,
          width: 240,
          overflowX: 'unset',
          overflowY: 'unset',
          boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
          '&::before': {
            content: '""',
            position: 'absolute',
            marginRight: '-0.71em',
            top: -10,
            left: 50,
            width: 10,
            height: 10,
            backgroundColor: 'background.paper',
            boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
            transform: 'translate(-50%, 50%) rotate(-45deg)',
            clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
          },
        },
      }}
    >
      <Button sx={{ p: 0, mb: 2 }} onClick={handleProfileClick}>Моя страница</Button>
      <Divider sx={{ mb: 2 }} />
      <MenuList sx={{ p: 0 }}>
        {
          userCompanies?.map((company) => {
            const isCurrentCompany = company.id === currentCompanyId;
            return (
              <MenuItem
                sx={{ px: 0, justifyContent: 'space-between', cursor: !isCurrentCompany ? 'pointer' : 'default' }}
                onClick={() => !isCurrentCompany && handleClickCompany(company.id)}
              >
                <Stack>
                  <Typography
                    variant="body16rg"
                    sx={{
                      display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 170,
                    }}
                  >
                    {company.shortName}
                  </Typography>
                  <Typography variant="body14rg" sx={{ color: 'base.200' }}>
                    {company.currentUserPosition}
                  </Typography>
                </Stack>
                {
                  isCurrentCompany && <CurrentCompanyIcon />
                }
              </MenuItem>
            );
          })
        }
      </MenuList>
    </Popover>
  );
};

export default UserMenu;

import { createTheme } from '@mui/material';
import components from './components';
import palette from './palette';
import typography from './typography';

const createAppTheme = () => createTheme(
  {
    components,
    palette,
    typography,
  },
);

export default createAppTheme;

import React from 'react';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { TrialResultQuery, useTrialResultQuery } from '@generated/graphql';
import { Stack } from '@mui/material';
import { groupBy } from 'lodash';
import RepositoryCheck from './components/blocks/RepositoryCheck/RepositoryCheck';
import CodeReviewCommon from './components/blocks/CodeReviewCommon';
import CommonInfo from './components/blocks/CommonInfo';
import RecommendationBlock from './components/blocks/RecomendationBlock';
import AnswerStatistics from './components/blocks/AnswerStatistics';
import CandidateAnswers from './components/blocks/CandidateAnswers';

export type TrialAnswersType = NonNullable<TrialResultQuery['trial']>['trialAnswers'];

export const groupAnswers = (result: TrialAnswersType, group: 'blocks' | 'competence') => groupBy(
  result,
  (value) => {
    const { topic } = value.question;
    const { competence } = topic;
    const blocks = competence.CompetenceKnowledgeBlocks?.map((map: any) => map.knowledgeBlock.id).join(',');
    return group === 'blocks' ? blocks : `${blocks}-${competence.title}`;
  },
);

const ResultTab = () => {
  const { trialId } = useParams();
  const { data, loading } = useTrialResultQuery({
    variables: {
      trialId: trialId!,
    },
  });
  const result = data?.trial;
  const trialAnswers = result?.trialAnswers;

  const isRepositoryCheckExist = !!result?.trialRepositoryChecks?.length;
  const lastRepositoryCheck = result?.trialRepositoryChecks?.[0];

  if (loading) return <CircularLoading />;

  return (
    <Stack py={2.5} spacing={2.5}>
      <CommonInfo score={result?.candidateScore!} />
      {isRepositoryCheckExist && lastRepositoryCheck && (
      <CodeReviewCommon trialRepositoryCheck={lastRepositoryCheck} />)}
      <RecommendationBlock answers={trialAnswers} />
      <AnswerStatistics answers={trialAnswers} />
      <CandidateAnswers answers={trialAnswers} />
      {isRepositoryCheckExist && lastRepositoryCheck
          && (<RepositoryCheck trialRepositoryCheck={lastRepositoryCheck} />)}
    </Stack>
  );
};

export default ResultTab;

import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet, useLocation } from 'react-router-dom';
import ROUTES from 'constants/routes';
import AuthContainer from 'components/AuthContainer';
import Toolbar from '@mui/material/Toolbar';
import Header from './Header';

const MainLayout = () => {
  const location = useLocation();
  const isAuthPage = [ROUTES.USER_REGISTER,
    ROUTES.COMPANY_REGISTER,
    ROUTES.LOGIN].some((route) => location.pathname.includes(route));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box
        component="main"
        sx={{
          backgroundColor: '#F6F8FF',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {
          isAuthPage ? (
            <AuthContainer>
              <Outlet />
            </AuthContainer>
          ) : (
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4, pb: 4 }}>
              <Outlet />
            </Container>
          )
        }
        <Toolbar />
      </Box>
    </Box>
  );
};

export default MainLayout;

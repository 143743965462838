import { Competence_Programing_Language, Grade } from '@generated/graphql';
import { generateOptions } from 'helpers/index';

const PROGRAMING_LANGUAGE_TITLE: { [key in Competence_Programing_Language]: string } = {
  [Competence_Programing_Language.Java]: 'Java',
  [Competence_Programing_Language.Php]: 'PHP',
  [Competence_Programing_Language.Python]: 'Python',
  [Competence_Programing_Language.Swift]: 'Swift',
  [Competence_Programing_Language.JavaScript]: 'JavaScript',
  [Competence_Programing_Language.VentureInvestment]: 'Venture Investment',
  [Competence_Programing_Language.Kotlin]: 'Kotlin',
  [Competence_Programing_Language.DataScience]: 'Data Science',
  [Competence_Programing_Language.CSharp]: 'C#',
  [Competence_Programing_Language.AqaJava]: 'AQA Java',
  [Competence_Programing_Language.Qa]: 'QA',
  [Competence_Programing_Language.OneC]: '1C',
  [Competence_Programing_Language.SystemAnalyst]: 'Системный аналитик',
  [Competence_Programing_Language.OneCErp]: '1C ERP',
  [Competence_Programing_Language.MachineLearning]: 'Machine Learning',
};

const PROGRAMING_LANGUAGE_OPTIONS = generateOptions(PROGRAMING_LANGUAGE_TITLE);

const GRADE_OPTIONS = [
  {
    id: Grade.Junior,
    displayName: 'Junior',
  },
  {
    id: Grade.Middle,
    displayName: 'Middle',
  },
  {
    id: Grade.Senior,
    displayName: 'Senior',
  },
];

export { PROGRAMING_LANGUAGE_TITLE, PROGRAMING_LANGUAGE_OPTIONS, GRADE_OPTIONS };

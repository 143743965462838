import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Stack, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import _ from 'lodash';
import { generateFilterQuery } from 'components/generators/filtersGenerator';
import CircularLoading from 'components/CircularLoading';
import useList from 'hooks/useList';
import { DEFAULT_LIMIT } from 'components/ListPagination';
import { useNavigate } from 'react-router-dom';
import SortingButton from 'layout/ListLayout/SortingButton';
import List from 'layout/ListLayout/List';
import ButtonWithPermissions from 'components/buttons/ButtonWithPermissions';
import Filters from './Filters';

type ListLayoutProps = {
  title: string;
  filters: any;
  list: any;
  CardComponent: FC<any>;
  loading: boolean;
  error?: ApolloError;
  refetch: any;
  totalCount: number | undefined;
  defaultFilter?: object;
  defaultOrder?: { order: Order; field: any };
  emptyPlaceholderText: string;
  sortingList?: { buttonText: string; fieldName: string }[],
  isHideCreateButton?: boolean;
  createUnavailableText?: string;
};

export type Order = 'asc' | 'desc';

const ListLayout = ({
  title, filters, list,
  CardComponent, loading, error,
  refetch, totalCount, defaultFilter, defaultOrder,
  sortingList,
  emptyPlaceholderText, isHideCreateButton, createUnavailableText,
}: ListLayoutProps) => {
  const navigate = useNavigate();

  const {
    limit, page, params, changeSort, order, orderBy,
  } = useList();
  const form = useForm();

  const [sorting, setSorting] = useState<{
    order: Order | undefined;
    orderBy: string | undefined
  }>({
    order: order || defaultOrder?.order,
    orderBy: orderBy || defaultOrder?.field,
  });

  useEffect(() => {
    if (refetch) {
      refetch({
        page: page && Number(page) !== 0 ? Number(page) - 1 : 0,
        limit: Number(limit) || DEFAULT_LIMIT,
        filter: filters
          ? _.merge(defaultFilter, generateFilterQuery(filters, params))
          : defaultFilter,
        order: sorting.order && sorting.orderBy
          ? {
            order: sorting.order.toLocaleUpperCase(),
            field: sorting.orderBy,
          } : undefined,
      });
    }
  }, [params]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5}>
        <Typography variant="h28">
          {title}
        </Typography>
        {!isHideCreateButton && (
          <ButtonWithPermissions
            tooltipText={createUnavailableText}
            onClick={() => navigate('create')}
            buttonText="Создать"
          />
        )}
      </Stack>
      <Filters form={form} filters={filters} />
      {!!sortingList && (
      <Stack direction="row" spacing={2.5} mb={2.5}>
        {sortingList.map((button) => (
          <SortingButton
            key={`sorting-button-${button.fieldName}`}
            text={button.buttonText}
            sorting={sorting}
            fieldName={button.fieldName}
            onSort={() => {
              const newSort = {
                order: (sorting.orderBy === button.fieldName && sorting.order === 'asc' ? 'desc' : 'asc') as Order,
                orderBy: button.fieldName,
              };
              changeSort(newSort.order, newSort.orderBy);
              setSorting(newSort);
            }}
          />
        ))}
      </Stack>
      )}
      {loading ? <CircularLoading /> : (
        <List
          list={list}
          CardComponent={CardComponent}
          emptyPlaceholderText={emptyPlaceholderText}
          error={error}
          totalCount={totalCount}
        />
      )}
    </Box>
  );
};

export default ListLayout;

ListLayout.defaultProps = {
  error: undefined,
  defaultFilter: undefined,
  defaultOrder: undefined,
  sortingList: undefined,
};

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const HomeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4 12.1089L11.3477 5.25223C11.4333 5.17227 11.535 5.10883 11.647 5.06556C11.7589 5.02228 11.8788 5 12 5C12.1212 5 12.2411 5.02228 12.353 5.06556C12.465 5.10883 12.5667 5.17227 12.6523 5.25223L20 12.1089M5.84615 10.3861V18.1386C5.84615 18.6141 6.25969 19 6.76923 19H10.1538V15.2673C10.1538 14.7918 10.5674 14.4059 11.0769 14.4059H12.9231C13.4326 14.4059 13.8462 14.7918 13.8462 15.2673V19H17.2308C17.7403 19 18.1538 18.6141 18.1538 18.1386V10.3861M8.92308 19H15.6923"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default HomeIcon;

import React, { useContext } from 'react';
import {
  Exact,
  Question_Type,
  TopicQuery,
  useConfirmOrRejectQuestionMutation,
} from '@generated/graphql';
import {
  Chip, Stack, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ConfirmModalContext } from 'context/confirmModalContext';
import Button from '@mui/material/Button';
import { formatNumber, gradeColor } from 'helpers/index';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import MarkdownFormatter from 'components/MarkdownFormatter';
import { QueryResult } from '@apollo/client';
import { QuestionItemType } from 'pages/Topic/view/types/topic';
import ActionButtons from './ActionButtons';

interface QuestionItemProps {
  question: QuestionItemType;
  topicQuery: QueryResult<TopicQuery, Exact<{ topicId: string }>>;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ question, topicQuery }) => {
  const { data, refetch: refetchTopic } = topicQuery;
  const topic = data?.topic;
  const [confirmOrRejectQuestion,
    { loading: confirmRejectLoading },
  ] = useConfirmOrRejectQuestionMutation();
  const { handleOpen } = useContext(ConfirmModalContext);
  const { addToast } = useContext(ToastContext);

  // const isConfirm = question.confirmation;
  // const isMicroCriterionExist: boolean = !!question.microCriterion?.length;

  const onConfirmOrReject = () => confirmOrRejectQuestion({
    variables: {
      questionId: question.id,
    },
  });

  const onConfirm = () => {
    handleOpen({
      title: 'Вы уверены, что хотите подтвердить вопрос?',
      actionButtonText: 'Подтвердить',
      loading: confirmRejectLoading,
      handleClick: async () => onConfirmOrReject().then(() => {
        refetchTopic();
        addToast({ type: ToastTypeEnum.SUCCESS });
      }).catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });
  };

  const { type } = question;

  return (
    <Box sx={{ position: 'relative' }}>
      <ActionButtons topic={topic} question={question} refetchTopic={refetchTopic} />
      <Stack spacing={2} sx={{ py: 2.5 }}>
        <Stack spacing={2} sx={{ pr: 3 }}>
          {
            type === Question_Type.Practical && (
              <Stack direction="row" spacing={2.5} alignItems="center">
                <Box sx={{
                  borderRadius: 2, backgroundColor: 'blue', px: 1.5, py: 1,
                }}
                >
                  <Typography variant="body14rg" sx={{ color: 'white' }}>
                    Практика
                  </Typography>
                </Box>
                <Typography variant="body14rg" sx={{ color: 'primary.main' }}>
                  {`Подтип: ${question.subtype}`}
                </Typography>
              </Stack>
            )
          }
          {/* { */}
          {/*  isConfirm && !isMicroCriterionExist && ( */}
          {/*    <Alert sx={{ maxWidth: 490 }}> */}
          {/*      Производится генерация микрокритериев, */}
          {/*      до&nbsp;момента окончания процесса вопрос */}
          {/*      не&nbsp;будет использоваться в&nbsp;тестировании. */}
          {/*    </Alert> */}
          {/*  ) */}
          {/* } */}
          <MarkdownFormatter text={question.text} variant="body16rg" />
          <Stack direction="row" spacing={1.5} sx={{ '& > div': { display: 'inherit' } }}>
            {question.grade && (
              <Chip
                size="small"
                label={question.grade}
                sx={{
                  backgroundColor: `${gradeColor(question.grade)}.light`,
                  color: `${gradeColor(question.grade)}.contrast`,
                  width: 70,
                }}
              />
            )}
            <Box sx={{
              boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
              p: 1,
              borderRadius: 1,
              width: 125,
            }}
            >
              <Typography variant="body14rg" sx={{ color: 'primary.main' }}>Время ответа</Typography>
            </Box>
            <Box
              sx={{
                py: 1,
                px: 1.5,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                width: 128,
              }}
            >
              <Typography variant="body14rg">
                {formatNumber(['минута', 'минуты', 'минут'], Math.ceil(question.time / 60))}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1.5} sx={{ '& > div': { display: 'inherit' } }}>
            <Box sx={{
              boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
              p: 1,
              borderRadius: 1,
            }}
            >
              <Typography variant="body14rg" sx={{ color: 'primary.main' }}>Рекомендуемое время ответа</Typography>
            </Box>
            <Box
              sx={{
                py: 1,
                px: 1.5,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                width: 128,
              }}
            >
              <Typography variant="body14rg">
                {formatNumber(['минута', 'минуты', 'минут'], Math.ceil((question.recommendedTime || 0) / 60))}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          {question.criterion.map((criteria, index) => (
            <Box
            // eslint-disable-next-line react/no-array-index-key
              key={`criteria-${index}`}
              sx={{
                backgroundColor: 'bgSwitch', px: 1, py: 0.5, borderRadius: 1,
              }}
            >
              <Typography variant="body14rg" sx={{ color: 'primary.dark' }}>{criteria}</Typography>
            </Box>
          ))}
        </Stack>
        {!question.confirmation && (
        <Button variant="contained" onClick={onConfirm} sx={{ width: 'fit-content' }}>
          Подтвердить
        </Button>
        )}
      </Stack>
    </Box>
  );
};
export default QuestionItem;

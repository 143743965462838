import React from 'react';
import { groupAnswers, TrialAnswersType } from 'pages/Testing/view/components/ResultTab';
import { Typography, Grid } from '@mui/material';
import CollapsedBlock from '../CollapsedBlock';

export enum Decision {
  correct = 'correct',
  almost_correct = 'almost_correct',
  partial_correct = 'partial_correct',
  partial_incorrect = 'partial_incorrect',
  incorrect = 'incorrect',
}

export type RecommendationBlockProps = {
  answers: TrialAnswersType
};

const RecommendationBlock = ({ answers }:
RecommendationBlockProps) => {
  const filteredAnswers = answers?.filter((answer) => answer.score! < 40);
  const groupedByBlock = filteredAnswers ? Object.entries(groupAnswers(filteredAnswers, 'blocks')) : undefined;
  const isThemesExist = filteredAnswers?.length;

  return (
    <CollapsedBlock title="Темы, рекомендуемые к повторению">
      <Grid item xs={12} lg={6}>
        {isThemesExist ? (
          <ul>
            {
          groupedByBlock?.map((item) => {
            const blocks = item[1][0].question.topic.competence.CompetenceKnowledgeBlocks?.map((map: { knowledgeBlock: { title: any; }; }) => map.knowledgeBlock.title).join(', ');
            return (
              <li>
                {blocks}
                <ul>
                  {
                    Object.entries(groupAnswers(item[1], 'competence')).map((competence) => (
                      <li>
                        <Typography variant="body14rg">
                          {competence[1][0].question.topic.competence.title}
                        </Typography>
                        <ul>
                          {
                            competence[1].map((answer) => (
                              <li>
                                <Typography variant="body14rg">
                                  {answer.question.topic.title}
                                </Typography>
                              </li>
                            ))
                          }
                        </ul>
                      </li>
                    ))
                  }
                </ul>
              </li>
            );
          })
        }

          </ul>
        ) : (
          <Typography variant="body14rg">
            Кандидат набрал по всем темам более 40 баллов
          </Typography>
        )}
      </Grid>
    </CollapsedBlock>

  );
};

export default RecommendationBlock;

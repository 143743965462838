import React, { useContext, useState } from 'react';
import { UserContext } from 'context/userContext';
import CircularLoading from 'components/CircularLoading';
import UserLogin from './UserLogin';
import SelectCompany from './SelectCompany';

const Login = () => {
  const [step, setStep] = useState(0);
  const {
    me, meError, meLoading,
  } = useContext(UserContext);

  if (meLoading) return <CircularLoading />;

  return step === 0 && (!me || meError) ? <UserLogin setStep={setStep} /> : <SelectCompany />;
};

export default Login;

import { Grid } from '@mui/material';
import ListPagination from 'components/ListPagination';
import EmptyList from 'components/EmptyList';
import React, { FC } from 'react';
import { ApolloError } from '@apollo/client';

type ListProps = {
  list: any;
  CardComponent: FC<any>;
  error?: ApolloError;
  totalCount: number | undefined;
  emptyPlaceholderText: string;
};

const List = ({
  error, list, totalCount, emptyPlaceholderText, CardComponent,
}: ListProps) => {
  if (!error && list && list.length) {
    return (
      <>
        <Grid container mb={2.5} rowSpacing={2.5} columnSpacing={2.5}>
          {
          list?.map((item: any) => (
            <Grid item xs={12} md={6} lg={3} key={`card-${item.id}`}>
              <CardComponent {...item} />
            </Grid>
          ))
        }
        </Grid>
        <ListPagination totalCount={totalCount!} />
      </>
    );
  }

  return (
    <EmptyList emptyPlaceholderText={emptyPlaceholderText} />
  );
};

export default List;

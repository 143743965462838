import React, { useContext } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import Form from 'components/form/Form';
import TextFieldControl from 'components/form/TextFieldControl';
import { useForm } from 'react-hook-form';
import { useCopyCompetencyMapMutation } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';

type CopyMapModalProps = {
  open: boolean;
  onClose: () => void;
  originalMap: { id: string, title: string }
};

const CopyMapModal = ({ open, onClose, originalMap }: CopyMapModalProps) => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const form = useForm<{ title: string }>({
    defaultValues: {
      title: originalMap.title,
    },
  });
  const [copyMap, { loading: copyMapLoading }] = useCopyCompetencyMapMutation();
  const { handleSubmit } = form;
  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const onSubmit = (formData: { title: string }) => {
    copyMap({
      variables: {
        input: {
          competencyMapId: originalMap.id,
          title: formData.title,
        },
      },
    }).then((res) => {
      const newMapId = res.data?.copyCompetencyMap.id;
      if (newMapId) {
        navigate(`/${ROUTES.COMPETENCE_MAPS}/view/${newMapId}`);
        addToast({ type: ToastTypeEnum.SUCCESS });
        onClose();
      } else addToast({ type: ToastTypeEnum.ERROR });
    }).catch(onError);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Дублировать карту компетенций
      </DialogTitle>
      <DialogContent>
        <Form form={form}>
          <TextFieldControl name="title" label="Название новой карты компетенций" rules={{ required: true }} />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit, onError)}
          loading={copyMapLoading}
          variant="contained"
        >
          Дублировать
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyMapModal;

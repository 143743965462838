import { useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { formatISODate } from 'helpers/date';
import cookies from 'js-cookie';

const useDownloadReport = (
  trialId: string,
  isWithExplanations: boolean,
  fullName: string | undefined | null,
  date: string,
) => {
  const { addToast } = useContext(ToastContext);
  const accessToken = cookies.get('accessToken');
  const onDownloadReport = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/trial/fetch-result?trialId=${trialId}&isWithExplanations=${isWithExplanations}`,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      },
    ).then((resp) => {
      if (resp.ok) return resp.blob();
      throw new Error();
    })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fullName}_${formatISODate(date, 'dd.MM.yyyy HH:mm')}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => addToast({
        type: ToastTypeEnum.ERROR,
        text: 'Произошла ошибка. Попробуйте позже.',
      }));
  };
  return onDownloadReport;
};

export default useDownloadReport;

import React, { FC } from 'react';
import Box from '@mui/material/Box';
import {
  Card, Chip, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { formatISODate } from 'helpers/date';
import DownloadIcon from 'assets/icons/DownloadIcon';
import TestingStatus from 'components/TestingStatus';
import { Trial_Status, TrialsQuery } from '@generated/graphql';
import { gradeColor } from 'helpers/index';
import CardHeader from 'components/CardHeader';
import { useNavigate } from 'react-router-dom';
import useDownloadReport from 'hooks/useDownloadReport';

type CardProps = NonNullable<TrialsQuery['trials']['data']>[number];

const TestingCard:FC<CardProps> = ({
  createdAt, numericId, status, competencyMap, companyCandidate, id, completedAt,
}) => {
  const navigate = useNavigate();
  const onDownloadReport = useDownloadReport(
    id,
    false,
    companyCandidate.fullName,
    completedAt,
  );

  return (
    <Card
      onClick={() => navigate(`view/${id}`)}
      sx={{
        display: 'block', height: '100%', textDecoration: 'none', cursor: 'pointer',
      }}
    >
      <Stack spacing={2.5}>
        <Stack
          alignItems="center"
          sx={{
            borderRadius: 1, backgroundColor: 'bgSwitch', py: 0.6, px: 1.5, minHeight: 50,
          }}
          spacing={2}
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="subtitle18lg">
            {companyCandidate.fullName}
          </Typography>
          {status === Trial_Status.Completed && (
            <IconButton onClick={(e) => {
              e.stopPropagation();
              onDownloadReport();
            }}
            >
              <DownloadIcon />
            </IconButton>
          )}
        </Stack>
        <Box>
          <CardHeader title={competencyMap.title} />
          <Divider sx={{ mt: 1.5 }} />
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box sx={{
            backgroundColor: 'bgSwitch', borderRadius: 2, px: 1.7, py: 1,
          }}
          >
            <Typography variant="body16rg">
              {`ID ${numericId}`}
            </Typography>
          </Box>
          {companyCandidate.grade && (
            <Chip
              label={companyCandidate.grade}
              sx={{
                backgroundColor: `${gradeColor(companyCandidate.grade)}.light`,
                color: `${gradeColor(companyCandidate.grade)}.contrast`,
              }}
            />
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <TestingStatus status={status} />
          <Stack justifyContent="space-between" spacing={0.5}>
            <Typography
              variant="body14rg"
              sx={{
                '& > span':
                  { color: 'text.disabled' },
                display: 'inline-flex',
                justifyContent: 'space-between',
              }}
            >
              <span>созд.</span>
              {' '}
              {formatISODate(createdAt, 'dd.MM.yy')}
            </Typography>
            {completedAt && (
            <Typography
              variant="body14rg"
              sx={{
                '& > span':
                  { color: 'text.disabled' },
                display: 'inline-flex',
                justifyContent: 'space-between',
              }}
            >
              <span>заверш.</span>
              {' '}
              {formatISODate(completedAt, 'dd.MM.yy')}
            </Typography>
            )}
          </Stack>

        </Stack>

      </Stack>
    </Card>
  );
};

export default TestingCard;

import CollapsedBlock from 'pages/Testing/view/components/ResultTab/components/CollapsedBlock';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

interface TextBlockProps {
  title: string;
  text: string | React.ReactNode;
}

const TextBlock = ({ title, text }: TextBlockProps) => (
  <CollapsedBlock title={title}>
    <Grid item xs={12} lg={6}>
      <Box sx={{ boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)', borderRadius: 1, p: 1.5 }}>
        <Typography variant="body14rg">
          {text}
        </Typography>
      </Box>
    </Grid>
  </CollapsedBlock>
);

export default TextBlock;

import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface CreateTestingProps {
  competencyMap?: {
    __typename?: 'CompetencyMap',
    id: string,
    knowledgeBlocks?: Array<{
      id: string,
      knowledgeBlockCompetences?: Array<{
        id: string,
        competence: {
          id: string,
          topics?: Array<{
            id: string,
            questions?: Array<{
              id: string, confirmation: boolean }> | null
          }> | null
        }
      }> | null
    }> | null
  } | null
}

const useCreateTesting = ({ competencyMap }: CreateTestingProps) => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);

  const mapId = competencyMap?.id;

  const createTesting = () => {
    const blocks = competencyMap?.knowledgeBlocks;
    if (!blocks?.length || blocks.every((block) => !block.knowledgeBlockCompetences?.length)) {
      addToast({
        type: ToastTypeEnum.ERROR,
        text: 'Невозможно создать тестирование, так как карта компетенций пустая.',
      });
      return;
    }
    if (blocks.some((block) => !block.knowledgeBlockCompetences?.length)) {
      addToast({
        type: ToastTypeEnum.ERROR,
        text: 'Невозможно создать тестирование, так как блок знаний "название блока знаний" не содержит компетенции.',
      });
      return;
    }

    let topic: any;
    // eslint-disable-next-line no-restricted-syntax
    for (const block of blocks) {
      if (!block.knowledgeBlockCompetences?.length) break;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      block.knowledgeBlockCompetences.every((kbc) => {
        if (!kbc.competence.topics?.length) return false;
        topic = kbc.competence.topics?.find((t) => t.questions?.every((q) => !q.confirmation));
        return topic;
      });
    }

    if (topic) {
      addToast({
        type: ToastTypeEnum.ERROR,
        text: `Невозможно создать тестирование, так как топик ${topic.title} не содержит вопросы.`,
      });
    } else navigate(`/testing/create?mapId=${mapId}`);
  };

  return { createTesting };
};

export default useCreateTesting;

import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ApolloProvider } from '@apollo/client';
import { ToastProvider } from 'context/toastContext';
import { ModalProvider } from 'context/confirmModalContext';
import { UserProvider } from 'context/userContext';
import { CompanyProvider } from 'context/companyContext';
import createAppTheme from './theme';
import router from './router';
import client from './config/apolloConfig';
import './assets/style.css';
import 'highlight.js/styles/github.css';

const App = () => {
  const theme = createAppTheme();
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <UserProvider>
              <CompanyProvider>
                <ModalProvider>
                  <CssBaseline />
                  <RouterProvider router={router} />
                </ModalProvider>
              </CompanyProvider>
            </UserProvider>
          </ToastProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
};

export default App;

import React, { useContext } from 'react';
import ListLayout from 'layout/ListLayout';
import {
  useCompetencesQuery,
} from '@generated/graphql';
import useSetTabTitle from 'hooks/useSetTabTitle';
import { CompanyContext } from 'context/companyContext';
import { generateUnavailableText } from 'components/buttons/ButtonWithPermissions';
import CompetencyCard from './CompetencyCard';
import filterFields from './filterFields';

const CompetenciesList = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);

  const {
    data, loading, error, refetch,
  } = useCompetencesQuery({
    variables: {
      page: 0,
      limit: 8,
      filter: {},
    },
  });
  const list = data?.competences.data;
  const createUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, 'создания компетенций');

  useSetTabTitle('Компетенции');
  return (
    <ListLayout
      createUnavailableText={createUnavailableText}
      title="Компетенции"
      filters={filterFields}
      CardComponent={CompetencyCard}
      list={list}
      loading={loading}
      error={error}
      refetch={refetch}
      totalCount={data?.competences.totalCount}
      emptyPlaceholderText="Пока нет компетенций"
    />
  );
};

export default CompetenciesList;

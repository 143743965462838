import React from 'react';
import Button from '@mui/material/Button';
import { Order } from 'layout/ListLayout/index';
import SortAscIcon from 'assets/icons/SortAscIcon';
import SortDescIcon from 'assets/icons/SortDescIcon';

type SortingButtonProps = {
  text: string;
  sorting: {
    order: Order | undefined;
    orderBy: string | undefined;
  };
  fieldName: string;
  onSort: () => void;
};

const SortingButton = ({
  text, sorting, fieldName, onSort,
}: SortingButtonProps) => {
  const { order, orderBy } = sorting;
  const isActive = orderBy === fieldName;
  return (
    <Button
      onClick={onSort}
      color="secondary"
      variant="contained"
      sx={{
        backgroundColor: 'white',
        ...(isActive && { color: 'primary.main' }),
      }}
      endIcon={order === 'desc' && isActive ? <SortDescIcon /> : <SortAscIcon />}
    >
      {text}
    </Button>
  );
};

export default SortingButton;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const LogoutIcon = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9993 5.33496H6.66602V24.0016C6.66602 24.7089 6.94697 25.3871 7.44706 25.8872C7.94716 26.3873 8.62544 26.6683 9.33268 26.6683H19.9993M21.3327 20.0016L25.3327 16.0016M25.3327 16.0016L21.3327 12.0016M25.3327 16.0016H11.9993"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default LogoutIcon;
